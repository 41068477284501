const mybioma = {
  palette: {
    primary: {
      light: '#fff',
      main: '#a9b92f',
      dark: '#96a52a',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const biogena = {
  palette: {
    primary: {
      light: '#fff',
      main: '#6ea665',
      dark: '#6ea665',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"DINNextLTPro", serif',
  },
};

const medidtcine = {
  palette: {
    primary: {
      light: '#fff',
      main: '#106D88',
      dark: '#106D88',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const wellgen = {
  palette: {
    primary: {
      light: '#fff',
      main: '#183029',
      dark: '#183029',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const woelfel = {
  palette: {
    primary: {
      light: '#fff',
      main: '#37686B',
      dark: '#37686B',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const ippm = {
  palette: {
    primary: {
      light: '#fff',
      main: '#41a62a',
      dark: '#41a62a',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const longevity = {
  palette: {
    primary: {
      light: '#fff',
      main: '#004976',
      dark: '#004976',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const longevityOeschberghof = {
  palette: {
    primary: {
      light: '#fff',
      main: '#004976',
      dark: '#004976',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const kalla = {
  palette: {
    primary: {
      light: '#fff',
      main: '#2e2630',
      dark: '#2e2630',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Roobert", serif',
  },
};

const gutmore = {
  palette: {
    primary: {
      light: '#fff',
      main: '#037b7e',
      dark: '#037b7e',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Mukta", serif',
  },
};

const analiza = {
  palette: {
    primary: {
      light: '#fff',
      main: '#c20f2f',
      dark: '#c20f2f',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const medgen = {
  palette: {
    primary: {
      light: '#fff',
      main: '#ee7203',
      dark: '#ee7203',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const bianchini = {
  palette: {
    primary: {
      light: '#fff',
      main: '#0c8686',
      dark: '#0c8686',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const ramuslab = {
  palette: {
    primary: {
      light: '#fff',
      main: '#009640',
      dark: '#009640',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const nmgenomix = {
  palette: {
    primary: {
      light: '#fff',
      main: '#257234',
      dark: '#257234',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const mavie = {
  palette: {
    primary: {
      light: '#fff',
      main: '#166DC5',
      dark: '#166DC5',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"GT Walsheim", serif',
  },
};

const fontana = {
  palette: {
    primary: {
      light: '#fff',
      main: '#FC5002',
      dark: '#FC5002',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Geogrotesque", serif',
  },
};

const precisionLaboratory = {
  palette: {
    primary: {
      light: '#fff',
      main: '#b02328',
      dark: '#b02328',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Times New Roman", serif',
  },
};

const longevityZurich = {
  palette: {
    primary: {
      light: '#fff',
      main: '#004976',
      dark: '#004976',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Nunito", serif',
  },
};

const endomedix = {
  palette: {
    primary: {
      light: '#fff',
      main: '#61bdf0',
      dark: '#61bdf0',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Publica Play", serif',
  },
};

const saintCharles = {
  palette: {
    primary: {
      light: '#fff',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Helvetica Neue LT Pro", Helvetica, serif',
  },
};

const biomeMe = {
  palette: {
    primary: {
      light: '#fff',
      main: '#aad973',
      dark: '#d1db96',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Source Sans Pro", Helvetica, serif',
  },
};

const dahaVentures = {
  palette: {
    primary: {
      light: '#fff',
      main: '#003A34',
      dark: '#003A34',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontSize: 16,
    fontFamily: '"Antebas", serif',
  },
};

export {
  mybioma,
  biogena,
  medidtcine,
  wellgen,
  woelfel,
  ippm,
  longevity,
  longevityOeschberghof,
  kalla,
  gutmore,
  analiza,
  medgen,
  bianchini,
  ramuslab,
  nmgenomix,
  mavie,
  fontana,
  precisionLaboratory,
  longevityZurich,
  endomedix,
  saintCharles,
  biomeMe,
  dahaVentures,
};
